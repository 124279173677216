.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.account-cards {
  display: flex;
  /* justify-content: center; */
  width: 100%;
  flex-wrap: wrap;
}

.account-card {
  border: 2px solid #0d0a3f;
  padding: 20px;
  border-radius: 23px;
  width: 30%;
  min-width: 300px;
  color: #0d0a3f;
  margin: 10px 0;
     margin-right: 3%;;
}

.arrow {
  display: inline-block;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.create-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d0a3f;
  color: #fff;
  display: flex;
  align-items: center;
}
.create-button-text {
  margin-right: 10px;
}
.account-header-text {
  color: #0d0a3f;
  font-size: 24px;
  margin-bottom: 0;
}
.account-name {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}
.account-diver {
  height: 1px;
  background-color: #888888;
  margin: 20px 0;
}
.account-details {
  margin: 10px;
  display: flex;
}
.account-detail-type {
  flex: 1;
  text-align: start;
}
.account-detail-value {
  flex: 1;
  text-align: start;
  margin-left: 10px;
}
.tooltip-main {
  background-color: #fff;
  color: black;
}

.account-edit-input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 70%;
}

.account-edit-input:focus {
  outline: none;
}
.editbutton {
  padding: 10px;
  width: 20%;
  font-size: 10px;
}
