.tradedetail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid black;
  padding: 15px;
}

.tradedetail-header-left {
  display: flex;
}
.tradeDetail-header-Field {
  margin-right: 20px;
}
.tradeDetail-header-Field:hover {
  cursor: pointer 
}

.tradeDetail-tag-name {
  color: #0d0a3f80;
  size: 14px;
  margin-right: 10px;
  font-size: 14px;
}

.details {
  display: flex;
}

.left-details,
.right-details {
  flex: 1;
  padding: 10px;
}

.right-details img {
  max-width: 100%;
  margin-bottom: 10px;
}

.trade-notes h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.boxed-detail {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detail-title {
  font-weight: bold;
  margin-right: 10px;
}
.tradeDetail-label {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.tradeDeatil-withoutBorder {
  display: flex;
  margin: 20px;
  align-content: flex-start;
}
.detail-content {
  /* flex: 1; */
  text-align: right;
  font-size: 14px;
  font-weight: 700;
}
.tradeSymboleName {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.tradedeayils-risk {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.left-details {
  margin-right: 20px;
  flex: 0 0 50%;
}
