.dropdwon-label {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
  display: flex;
}
.dropdownInputfiled {
  width: 200px;
  border: 2px solid grey;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.menuitem {
  padding: 10px;
}
.customField {
  margin-right: 20px;
}
