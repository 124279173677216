.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 10px 50px;
  border-radius: 20px;
  width: 30%;
  text-align: center;
  @media screen and (max-width: 768px) {
    width:60%
  }
  @media screen and (max-width: 1024px) {
    width:50%;
  }
  @media screen and (max-width: 600px) {
    width:90%;
    padding: 10px 30px;
  }
}

.logo {
  width: 100px;
  margin: 0 auto 20px;
}

h2 {
  margin-bottom: 15px;
}

form label,
form input,
.remember-me {
  display: block;
  margin-bottom: 10px;
}

.remember-me label {
  margin-left: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.close-icon {
  text-align: right !important;
}

.material-icons {
  font-size: 20px !important;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: black;
}
.logo-design {
  width: 5rem !important;
  height: 5rem !important;
  margin: 30px 0 20px 0;
}
.logo-div {
  display: flex;
  justify-content: center;
}
.modal-content h3 {
  font-weight: normal;
  padding-bottom: 30px;
}
.signGoogle {
  width: 208px;
}
.googleLogo {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
}
.or {
  color: grey;
  padding-bottom: 20px;
  font-size: 10px;
}
.inputStyle {
  background: #fff;
  border: 1px solid #d3d3d3;
  font-size: small;
  height: 2rem;
  outline: none;
  padding: 2%;
  width: 100%;
}
.inputStyle::placeholder {
  color: black;
  font-size: 14px;
  padding: 0 5px;
}
.remember-me {
  display: flex;
  font-size: 14px;
  color: black;
  margin: 15px 0;
}
.submit-btn {
  background: #0d0a3f;
  border-radius: 0.5rem;
  color: #fff;
  margin-top: 5%;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.noaccount {
  margin: 20px 0;
}
