.custom-table-main {
  color: #0d0a3f;
}
.custom-table-heading {
  color: #0d0a3f;
}
.MuiTableRow-root {
  color: #0d0a3f;
}
.MuiTable-root {
  color: #0d0a3f;
}
