.navbar {
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.5rem rem);
  background: #ffffff80;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  white-space: nowrap;
}

.logo {
  height: 6rem;
  width: 8rem;
}

.d-inline-block {
}

.align-top {
  vertical-align: top !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.active-link {
  color: #0d0a3f;
  font-weight: 700;
}
.nav-link {
  display: block;
  padding: 0.5rem 0;
  font-size: 16px;
  font-weight: 600;
  color: #0000008c;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.toggler {
  border: none;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  color: #0000008c;
  background-color: transparent;
  border: 1px solid #0000001a;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
.collapse:not(.show) {
  display: none;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}
.gap-4 {
  gap: 1.5rem !important;
}
.justify-content-center {
  justify-content: center !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}
.justify-content-end {
  justify-content: flex-end !important;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: rgba(0, 0, 0, 0.55);
  --bs-nav-link-hover-color: rgba(0, 0, 0, 0.7);
  --bs-nav-link-disabled-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.sign-up {
  background: none;
  border: none;
  border: 1px solid grey;
  border-radius: 5px;
  height: 3rem;
  outline: none;
  padding: 1%;
  width: 6rem;
  color: #0d0a3f !important;
  margin-right: 20px;
}
.login {
  color: white !important;
  background: #0d0a3f !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1700px) and (min-width: 1599px) {
  .container {
    width: 1600px;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1499px) {
  .container {
    width: 1500px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1399px) {
  .container {
    width: 1400px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 1400px) and (min-width: 1299px) {
  .container {
    width: 1300px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 1300px) and (min-width: 1199px) {
  .container {
    width: 1200px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 1200px) and (min-width: 1099px) {
  .container {
    width: 1100px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 1100px) and (min-width: 999px) {
  .container {
    width: 1000px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 1000px) and (min-width: 899px) {
  .container {
    width: 900px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 900px) and (min-width: 799px) {
  .container {
    width: 800px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 800px) and (min-width: 699px) {
  .container {
    width: 700px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 700px) and (min-width: 599px) {
  .container {
    width: 600px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 600px) and (min-width: 499px) {
  .container {
    width: 500px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 500px) and (min-width: 399px) {
  .container {
    width: 400px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 400px) and (min-width: 299px) {
  .container {
    width: 300px; /* Adjust the width for this breakpoint */
  }
}
@media screen and (max-width: 300px) and (min-width: 199px) {
  .container {
    width: 200px; /* Adjust the width for this breakpoint */
  }
}

.navheader {
  display: flex;
  justify-content: space-around;
  flex: 0.4;
}

.navHeadercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navborder {
  border-bottom: 1px solid black;
}
.navButtonContanier {
  display: flex;
  flex: 0.2;
  justify-content: space-between;
}
.newContainer{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}
/* Media query for larger displays */
/* @media screen and (min-width: 1700px) {
  .newContainer {
    max-width: 1200px;
  }
} */