.footer {
  background: #0d0a3f;
  color: #fff !important;
  display: flex;
  padding: 2.5% 7.5%;

}
.section-1 {
  width: 40%;
  color: #fff !important;
  @media (max-width:48em) {
    width: 100%;
  }
}
.section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: justify;
}
.footer-logo {
  letter-spacing: normal;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin-bottom: 10px;
}
.footer-paragraph {
  font-size: 12pt;
  margin-left: 1%;
  width: 87.5%;
  letter-spacing: normal;
  color: #fff !important;
}
.footer-contact {
  display: flex;
  width: 80%;
  color: #fff !important;
}
.footer-contact span {
  color: #fff !important;
  letter-spacing: normal;
  align-self: center;
}
.footer-contact-icon {
  height: 2rem;
  width: 2rem;
  margin-right: 10px;
}
.downloads-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.download-button {
  width: 50%;
}
.section-2 {
  width: 15%;
  @media (max-width:48em) {
    width: 100%;
  }
}
.footer-section-heading {
  color: #fff;
  text-decoration: underline;
}
.disclouser {
  text-decoration: none;
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.footer-page-link {
  cursor: pointer;
  font-size: 16px;
  color: #b8b8b8 !important;
  letter-spacing: normal;
}
.footer-social-media-links {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-top: 20%;
}
.footer-social-media-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.section-3 {
  width: 20%;
  @media (max-width:48em) {
    width: 100%;
  }
}

.section-4 {
  width: 35%;
  @media (max-width:48em) {
    width: 100%;
  }
}

.copyright,
.footer-risk {
  font-size: 12pt;
  color: #fff !important;
  letter-spacing: normal;
}
.copyright {
  color: #fff;
  letter-spacing: normal;
  margin-bottom: 0;
  padding-bottom: 1rem;
  text-align: center;
  width: 100%;
}
.footer-outer {
  background-color: #0d0a3f;
  color: white !important;
}
a {
  text-decoration: none !important;
}
.footer-container-data{
  display: flex;
  @media (max-width:48em) {
    flex-direction: column;
  }
}