/* Dashboard.css */
.TransactionCard_dashboard {
    display: flex;
    background-color: white;
    padding:20px
  }
  .transaction-left{
    display: flex;
    flex-direction: column;
    flex:1;
    margin: 10px;
  }
  .transaction-right{
    display: flex;
    flex-direction: column;
    flex:2;
    margin: 10px;

  }
  .transaction_summmary_header{
    display: flex;
    flex-direction: column;
  }

  .summary {
    margin-bottom: 20px;
  }
  
  .graph {
    margin-bottom: 20px;
    display: flex;
    flex: 2;
  }
  
  .transactions  {
    display: flex;
flex-direction: column;
    margin-bottom: 10px;
    flex: 1;
  }
  .transaction {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
    color:#0D0A3F;
  }
  .transaction_Header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    color:#0D0A3F;
    border-bottom:2px solid #0D0A3F
  }
  
  .add-transaction-form {
    /* Styling for form */
  }
  .addTransactionForm{
    width: 100%;
    border: 2px solid #888888;
    border-radius: 10px;
    padding: 10px 10px;
  }
  /* Add responsive styling as necessary */
  .dropdownstyles{
    width: 100%;
  }