.root {
    &[data-variant='secondary'] {
      color: #0d0a3f !important;
      background: #fff;
      border-radius: 5px;
      border:1px solid grey;
      font-size: 16px;
    }
  
    &[data-variant='primary'] {
      background: #0d0a3f;
      color: #fff !important;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 576px) {
    .root {
      &[data-variant='secondary'] {
        color: #0d0a3f !important;
        background: #fff;
        border-radius: 5px;
        border:1px solid grey;
        font-size: 14px;
    
      }
    
      &[data-variant='primary'] {
        background: #0d0a3f;
        color: #fff !important;
        font-size: 14px;
      }
    }
  }