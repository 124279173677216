.pricing-heading {
  color: #0d0a3f;
  font-weight: 700;
  margin: 70px 0 20px 0;
  text-align: center;
  font-size: 30px;
  @media screen and (max-width: 576px) {
    font-size: 25px;
    margin: 30px 0 ;
  }
}
.Pricing .para {
  letter-spacing: normal;
  font-size:25px;
  color: #2e2e2e;
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.pricingCont{
  @media screen and (max-width: 576px) {
    margin: 30px 0 ;
  }
}
.pricing-header {
  background-color: #0d0a3f;
  padding: 50px 0;
  color: white;
  font-weight: 700;
  border-radius: 10px 10px 0px 0;
  text-decoration: underline;
}
.pricing-price {
  margin: 10% 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.pricing-details {
  display: flex;
  justify-content: center;
}

.circle-list li {
  text-align: left;
  font-weight: 600;
  color: #2e2e2e;
  font-size: 20px;
  letter-spacing: normal;
}
.pricePaid {
  display: grid !important;
}
.priceCut {
  text-decoration: line-through;
  font-size: 20px;
}
.priceOffer {
  text-decoration: none !important;
  background-color: rgb(154, 71, 71);
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 15px;
  margin: 10px;
  color: white;
  letter-spacing: normal;
}
.priceSub {
  font-size: 12px;
  letter-spacing: normal;
}
.pricing-btn {
  margin: 30px 10%;
  background-color: #0d0a3f;
  color: white;
  font-weight: 800;
  padding: 20px 0;
}
.card {
  border-radius: 10px;
}
.pricing-columns {
  display: flex;
  justify-content: space-around;
}
.pricing-box {
  margin: 40px 0;
}
