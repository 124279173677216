.notes-main-cont {
  display: flex;
  height: -webkit-fill-available;
}

.notes-side {
  flex: 1;
  color: #0d0a3f;
}
.notes-main {
  flex: 4;
}

.notes-side-main {
  padding-top: 10px;
}
.notes-side-content {
  text-align: left;
  margin: 15px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  padding: 5px;
}
.notes-side-content-active {
  text-align: left;
  margin: 15px 0;
  padding: 5px;

  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  color: #fff;
  background-color: #0d0a3f;
}
.notes-card-cont {
  color: black;
  border-radius: 15px;
  background-color: aqua;
  padding: 15px;
  min-width: 200px;
  width: 30%;
  margin: 10px;
  text-align: left;
}
.notes-card-p {
  text-align: justify;
  font-size: 15px;
}
.notes-card-head {
  margin-bottom: 20px;
}
.notes-main-card {
  display: flex;
  flex-wrap: wrap;
}
