.homeBanner {
  align-items: space-between;
  display: flex;
  padding-left: 5%;
  width: 100%;
  @media screen and (max-width: 576px) {
    flex-direction:column-reverse ;
    margin-bottom: 20px;
  }
}

.homeBanner-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 2.5% 0;
  text-align: justify;
  width: 45%;
  margin-right: 5%;
  @media screen and (max-width: 576px) {
    width: 100%;
    align-items: center;
    gap: 0rem;

  }
}

.homeBanner-logo {
  width: 80%;
  align-self: center;  
  @media screen and (max-width:768px) {
    display:none
  }
  
}

.homeBannerPara {
  font-weight: 600;
  color: #7a7777;
  font-size: 25px;
  @media screen and (max-width: 576px) {
    width: 100%;
    font-size: 20px;
    margin: 1rem 0

  }
}

.homeBanner-button {
  background: #0d0a3f;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
  padding: 15px 30px;
}
.benefitsImg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
p {
  font-size: large;
}

.homeBanner-image-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.homeBanner-image {
  width: 100%;
}

p {
  font-size: large;
  color: black;
}

* {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px !important;
}

/* about */
.about-sub {
  width: 100%;
  display: flex;
  @media screen and (max-width: 576px) {
flex-direction: column;
  }
}
.about-left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.about-right {
  width: 50%;
  text-align: left;
  @media screen and (max-width: 576px) {
    width: 100%;
    margin:20px 0
  }
}
.about-image {
  width: 80%;
  height: 300px;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.about-heading {
  font-weight: bold;
}
.about-paragraph {
  text-align: left;
  margin: 60px 0;
  color: #000;
  @media screen and (max-width: 576px) {
    margin:20px 0
  }
}

.about {
  align-items: space-between;
  background: #fafafb;
  display: flex;
  padding: 5% 0;
  width: 100%;
}

.home-sign-up-button {
  align-items: center;
  display: flex;
  justify-content: center;
width: 100%; 
 padding: 10px;
}

.home-button {
  background: #0d0a3f;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  outline: none;
  padding: 20px 40px;
}

.sign-up-container {
  align-items: center;
  display: flex;
  gap: 2rem;
  padding-top: 2%;
  width: 80%;
  @media screen and (max-width: 576px) {
    /* width:calc(90% - 40px); */
    width: 90%;
    justify-content: center;
    margin: 0 20px;
  }
}

/* premium-features */
.premiumcontainer {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}
.premium-features {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0;
}

.premium-features-heading {
  color: #0d0a3f;
  font-weight: 800;
  text-align: center;
  margin: 0 5%;
  font-size: 40px;
  @media screen and (max-width: 576px) {
    font-size:25px;

  }
}

.cards-container {
  display: flex;
  padding: 5%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.card-minimal {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 15px 20px 40px 20px;
  width: 40%;
  margin: 20px 0;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.premium-feature-icon {
  background: #e9e7ec;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 10px;
}

.premium-feature-text-container {
  width: 80%;
}

.premium-feature-heading {
  color: #0d0a3f;
  font-size: large;
  text-align: left;
  font-weight: 700;
}

.premium-feature-description {
  color: #666;
  text-align: left;
  font-weight: normal;
  margin-top: 1rem;
}

.premium-features-sign-up {
  height: 100%;
  padding: 1% 20px;
  width: fit-content;
  font-size: 16px;
}

/* descriptive-paragraph */
.descriptive-paragraph-image {
  width: 330px;
}

.descriptive-paragraph {
  align-items: center;
  background: #0d0a3f;
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 2% 7.5%;
  margin: 5% 0;
  @media screen and (max-width: 576px){
    padding: 30px 7.5%;
  }
}

.description-para {
  color: #fff;
  text-align: justify;
  @media screen and (max-width: 576px){
    margin:30px 20px;
    margin-top: 0;
  }
}

.descriptive-paragraph-image {
  height: 15rem;
  width: 20rem;
}

/* why-section */
.why-heading {
  font-weight: 800;
  text-align: center;
  font-size: 40px;
  color: #0d0a3f;
  @media screen and (max-width: 576px) {
    font-size:25px;

  }
}

.card-why {
  border-left: 4px solid #0d0a3f;
  text-align: justify;
}

/* benefits-section */
.benefits-section {
  align-items: center;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3% 0 3% 2.75%;
}

.benefits-heading {
  color: #0d0a3f;
  font-weight: 700;
  text-align: left;
  margin-bottom: 2rem;
}

.benefit {
  color: grey;
  text-align: left;
}
.benefintsCont{
  display: flex;
  @media screen and (max-width: 576px) {
flex-direction: column;
  }
}

.beenfistList{
  @media screen and (max-width: 576px) {
margin:20px 0      }
}

.benefit-list-image {
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.benefits-tablet {
  width: 400px;
}

.benefitsSection {
  background: #fafafb;
}

/* benefits-section */
.revamp-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5%;
  text-align: center;
  @media screen and (max-width: 576px) {
    padding: 5% 0;
  }
}

.revamp-heading {
  color: #0d0a3f;
  font-weight: 800;
  text-align: center;
  padding: 0 40px;
  @media screen and (max-width: 576px) {
    padding:  0;
  }
}

.revamp-semi-heading {
  color: grey;
  padding: 0 15%;
  @media screen and (max-width: 576px) {
    padding:  0;
  }
}

.revamp-paragraph {
  color: grey;
  text-align: justify;
}
/* improvement-tips */
.improvement-tips {
  align-items: center;
  background: #fafafb;
  display: flex;
  justify-content: space-around;
  padding: 2% 4% 2% 5%;
  text-align: justify;
  @media screen and (max-width: 576px) {
    padding: 2% 0;
  }
}
.improvement-tips-text-container {
  color: #0d0a3f;
}
.sign-up-container {
  align-items: center;
  display: flex;
  gap: 2rem;
  padding-top: 2%;

}
.improvement-tip-heading {
  font-weight: 800;
  color: #0d0a3f;
  text-align: left;
  font-size: 28px;
  margin-bottom: 20px;
  @media screen and (max-width: 576px) {
    font-size: 25px;
  }
}
.improvement-tip-paragraph {
  color: #0d0a3f;
  text-align: justify;
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.improvement-tips-image {
  width: 100%;
}
.improvementbottomText {
  font-weight: bold;
  margin-bottom: 20px;
}

/* pre-footer   */
.pre-footer {
  align-items: center;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2% 4%;
  text-align: center;
  @media screen and (max-width: 576px) {
    padding: 20px ;
  }
}
.pre-footer-heading {
  font-weight: 800;
  color: #0d0a3f;
  text-align: center;
  letter-spacing: initial;
  font-size: 40px;
  @media screen and (max-width: 576px) {
    font-size:24px;
  }
}
.pre-footer-paragraph {
  font-size: 25px;
  padding: 0 8%;
  color: grey;
  @media screen and (max-width: 576px) {
    font-size:18px;
  }
}
.pre-footer-sign-up {
  flex-direction: column;
  gap: 0;
}
.home-page{
  /* padding-right: 0; */
}




.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.homeBannerPara {
  color: #7a7777;
  font-size: 25px; 
  text-align: center;
  padding: 0 15%;
}
.title{
  font-size: 45px;
  color: #0d0a3f;
}
.para-text{
  color: #7a7777;
  margin: 30px 0;
  text-align: left;
  font-size: 22px;
}
.bg-gray{
  background: #fafafb;
}

.button-all{
  background: #0d0a3f;
  color: #fff !important;
  font-size: 22px;
  padding: 10px 20px;
}
.button-all:hover{
  background: #ffffff;
  color: #0d0a3f !important;
  font-size: 22px;
  border: 1px solid #0d0a3f;
  padding: 10px 20px;
}
.card-minimal {
    box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
    display: flex;
    margin: 20px 0;
    padding: 40px 35px 40px;
    width: 100%;
    border-radius: 12px;
}
.premium-feature-icon {
    background: #e9e7ec;
    border-radius: .5rem;
    height: 2.5rem;
    margin-right: 10px;
    width: 2.5rem;
}
.premium-feature-heading {
    color: #0d0a3f;
    font-size: large;
    font-weight: 700;
    text-align: left;
    font-size: 25px;
}
.bg-blue{
  background: #0d0a3f;
}
.border-left-style{
  border-left: 6px solid #0d0a3f;
}
.list-view{
    font-size: 20px;
    font-weight: 400;
    color: #7a7777;
    list-style: none;
    line-height: 2;
} 
.list-icon{
    height: 2.3rem;
    margin-right: .5rem;
    width: 2rem;
}
.text-title{
    font-size: 24px;
    color: #0d0a3f;
    margin-bottom: 5%;
}
@media screen and (max-width: 600px) {
  .title {
        font-size: 24px;
        color: #0d0a3f;
    }
    .homeBannerPara {
        color: #7a7777;
        font-size: 24px;
        text-align: center;
        padding: 0 15%;
    }
    .pt-5 {
        padding-top: 1.5rem !important;
    }
    .px-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .mob-m-0{
      margin: 0;
    }
    .mob-p-0{
      padding: 0;
    }
    .mob-ps-5{
      padding-left: 1.5rem!important;
    }
}