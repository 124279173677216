.contact-heading {
  color: #0d0a3f;
  font-size: large;
  font-weight: 800;
  text-align: center;
}
.outer-container {
  align-items: center;
  display: flex;
  gap: 10%;
  justify-content: center;
  margin: 150px 0;
  @media screen and (max-width: 481px) {
    width: 100%;
    margin:0;
    flex-direction: column;
    margin-bottom: 150px;
  }
}
.static-container {
  color: #0d0a3f;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 40%;
  text-align: left;
  @media screen and (max-width: 481px) {
    width: 100%;
  }
}
.semi-heading {
  font-family: Geneva;
  font-weight: 700;
  color: #0d0a3f;
}
.contactLeftmain {
  font-size: 50px;
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.contactusparagraph {
  color: #000;
  letter-spacing: normal;
}
.desc {
  font-size: 20px;
}
.contactSocial {
  text-decoration: underline !important;
}
a {
  color: #000;
}
.form-container {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 30px;
  @media screen and (max-width: 481px) {
    width: 100%;
  }
}
.form-container label {
  color: #0d0a3f;
  text-align: left;
  margin: 10px 0;
  font-weight: 100;
}
.form-container .input {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  padding: 10px;
  width: 30vw;
  @media screen and (max-width: 481px) {
    width: 100%;
  }
}
.form-container .submit {
  background-color: #0d0a3f;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0px;
  padding: 20px 0;
}
.ContactUs {
  text-align: left !important;
}
.formFIelds {
  display: flex;
  flex-direction: column;
}
